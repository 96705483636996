



























































import { IInput } from '@/components/form';
import Input from '@/components/form/input.vue';
import router from '@/router';
import { addUser } from '@/api/users';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
export default defineComponent({
  components: { Input },
  data() {
    return {
      userCreated: false,
      userCreationError: false,
      creatingUser: false,
      name: '',
      email: '',
      password: '',
      passwordConfirm: '',
      inputName: {} as IInput,
      inputEmail: {} as IInput,
      inputPassword: {} as IInput,
      inputPasswordConfirm: {} as IInput,
      modal: {
        isActive: true,
      },
    };
  },
  setup() {
    const { translations } = useContext();
    return {
      translations,
    };
  },
  created() {
    this.inputName = {
      id: 'user-name',
      name: 'name',
      label: 'Name',
      type: 'text',
      icon: 'mail_outline',
      placeholder: 'Insert your name',
      value: '',
    };
    this.inputEmail = {
      id: 'user-email',
      name: 'email',
      label: this.translations.auth.login.email,
      type: 'text',
      icon: 'mail_outline',
      placeholder: this.translations.auth.login.email_placeholder,
      value: '',
    };

    this.inputPassword = {
      id: 'user-password',
      name: 'password',
      label: this.translations.auth.login.password,
      type: 'password',
      icon: 'lock_open',
      placeholder: this.translations.auth.login.password_placeholder,
      onInput: this.checkPasswords,
      value: '',
    };
    this.inputPasswordConfirm = {
      id: 'user-password',
      name: 'password',
      label: 'Confirm password',
      type: 'password',
      icon: 'lock_open',
      placeholder: 'Enter password here',
      onInput: this.checkPasswords,
      value: '',
    };
  },
  methods: {
    checkPasswords() {
      if (this.password !== this.passwordConfirm) {
        this.inputPasswordConfirm = {
          ...this.inputPasswordConfirm,
          value: this.passwordConfirm,
          error: true,
          message: 'Passwords do not mach',
        };
      } else {
        this.inputPasswordConfirm = {
          ...this.inputPasswordConfirm,
          value: this.passwordConfirm,
          error: false,
          message: undefined,
        };
      }
    },

    cancel() {
      router.go(-1);
    },

    signup() {
      const { email, password, name } = this;
      const user = {
        name,
        email,
        password,
      };
      this.creatingUser = true;
      addUser(user)
        .then((response) => {
          this.creatingUser = false;
          if (response.status === 201) {
            this.userCreated = true;
          }
        })
        .catch((err) => {
          const emailError = err.response.data.email && err.response.data.email[0];
          this.creatingUser = false;

          if (emailError) {
            this.inputEmail = {
              ...this.inputEmail,
              value: this.email,
              error: true,
              message: emailError,
            };
          } else {
            this.userCreationError = true;
          }
        });
    },
  },
});
